<template>
  <div class="box">
    <!-- 隐私协议 -->
    <div class="text" v-if="title == '隐私协议'">
      <div class="title">微企乐用户隐私协议</div>
      <div>
        更新时间：2021年10月21日
        <br />生效时间：2021年10月21日
        <br />微企乐（以下亦称“我们”）深知个人信息对您的重要性，我们将按照法律法规的规定，保护您的个人信息及隐私安全。我们制定
        “隐私政策”并特别提示：希望您在使用微企乐及相关服务前仔细阅读并理解本指引，以便做出适当的选择。
        <br />
        <br />本指引将帮助你了解：
        <br />&nbsp;&nbsp;我们会遵循隐私政策收集、使用您的信息，但不会仅因您同意本指引而采用强制捆绑的方式一揽子收集个人信息。
        <br />&nbsp;&nbsp;当您开启相关功能或使用相关服务时，为实现功能、服务所必需，我们会收集、使用必要的个人信息。除非是为实现基本业务功能或根据法律法规要求所必需的必要个人信息，您均可以拒绝提供且不影响您开启或使用其他功能或服务。我们将在本指引中逐项说明哪些是必要信息。
        <br />&nbsp;&nbsp;如果您未登录帐号，我们会通过设备对应的标识符信息来保障信息推送的基本功能。如果您登录了帐号，我们会根据帐号信息实现信息推送。
        <br />&nbsp;&nbsp;精确地理位置、摄像头、麦克风、相册、存储权限，均不会默认开启，只有经过您的明示授权才会在为实现特定功能或服务时使用，您也可以撤回授权。特别需要指出的是，即使经过您的授权，我们获得了这些敏感权限，也不会在相关功能或服务不需要时而收集您的信息。
        <br />&nbsp;&nbsp;本指引适用于您通过微企乐移动应用程序（以下称“微企乐APP”）、微企乐网页版、供第三方网站和应用程序使用的微企乐软件开发工具包（SDK）和应用程序编程接口（API）方式来访问和使用我们的产品和服务（在本指引中，统称为“微企乐及相关服务”）。
        <br />&nbsp;&nbsp;下文将帮助您详细了解我们如何收集、使用、存储、传输、共享、转让（如适用）与保护个人信息；其中，有关您个人信息权益的重要内容我们已用加粗形式提示，请特别关注。
      </div>
      <br />一、我们可能收集的用户信息
      <br />&nbsp;&nbsp;我们提供服务时，可能会收集、储存和使用下列与您有关的信息。如果您不提供相关信息，可能无法注册成为我们的用户或无法享受我们提供的某些服务，或者无法达到相关服务拟达到的效果。
      <br />1、注册成为微企乐个人用户
      <br />&nbsp;&nbsp;为注册成为微企乐个人用户，以便我们为您提供基础的服务，诸如一键测额，分享海报，联系顾问等功能，需要收集您的手机号码及短信验证码以注册并创建微企乐账号，否则您将不能使用微企乐服务。如果您仅需使用浏览、搜索微企乐展示的产品、功能及服务介绍，您不需要注册成为微企乐用户并提供上述信息。
      <br />2、使用微企乐服务过程中收集信息
      <br />&nbsp;&nbsp;当您在使用微企乐服务过程中，为向您提供更契合您需求的微企乐服务、交互展示、搜索结果、识别账号异常状态，维护微企乐服务的正常运行，改进及优化您对微企乐服务的体验并保障您的账号安全，包括您使用微企乐服务以及使用方式的信息，并将这些信息进行关联：
      <br />1）使用信息
      <br />&nbsp;&nbsp;我们会收集您作为微企乐个人用户使用微企乐服务时提交或产生的信息，以及您作为微企乐企业/组织用户的最终用户使用微企乐服务时提交或产生的信息。如您需使用微企乐一键测额功能，您需提供您的位置信息、企业名称和社会信用统一代码；如您需要使用微企乐专属顾问功能，您需提供您的手机号码及短信验证码等。
      <br />2）完善个人资料
      <br />&nbsp;&nbsp;您完善个人资料时，我们会以弹窗形式请求您授权调用存储（或照片、相机）权限，以便我们获取您要用于上传的头像。您如果拒绝授权提供，将无法使用此功能，但不影响您正常使用微企乐的其他功能。
      <br />3）联系专属顾问
      <br />&nbsp;&nbsp;在您联系专属顾问时，我们会请求您授权微企乐调用电话权限，以便直接拨打专属顾问电话。您如果拒绝授权提供，将无法使用此功能，但不影响您正常使用微企乐的其他功能。
      <br />4）查看产品
      <br />&nbsp;&nbsp;在您查看微企乐产品时，我们可能会提供显示位置的服务，当您选择显示位置时，我们会请求您授权微企乐调用地理位置权限，并收集与本服务相关的位置信息。您如果拒绝授权提供精确地理位置信息，将无法使用此功能，但不影响您正常使用微企乐的其他功能。
      <br />二、我们可能如何使用用户信息
      <br />1、在我们提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性；
      <br />2、帮助我们设计新服务，改善我们现有服务；
      <br />3、使我们更加了解您如何接入和使用我们的服务，从而针对性地回应您的个性化需求；
      <br />4、软件认证或管理软件升级； 5、让您参与有关我们产品和服务的调查。
      <br />&nbsp;&nbsp;为了让您有更好的体验、改善我们的服务或您同意的其他用途，在符合相关法律法规的前提下，我们可能将通过某一项服务所收集的信息，以汇集信息或者个性化的方式，用于我们的其他服务。例如，在您使用我们的一项服务时所收集的信息，可能在另一服务中用于向您提供特定内容，或向您展示与您相关的、非普遍推送的信息。如果我们在相关服务中提供了相应选项，您也可以授权我们将该服务所提供和储存的信息用于我们的其他服务。
      <br />三、我们可能与第三方共享的用户信息
      <br />在下列情况下，我们可能会共享您的个人信息：
      <br />1、其他注册用户就您在微企乐活动中的违约行为发起诉讼程序时，我们将应该等注册用户的请求向该等注册用户披露您的用户信息；
      <br />2、应行政、司法部门的要求向该等机构提供您的用户信息；
      <br />3、您通过微企乐平台向第三方合作机构申请相关产品服务时，我们将根据您的授权向第三方提供您的用户信息；
      <br />4、接入第三方软件开发工具包（SDK）或其他应用程序
      <br />•微企乐APP可能会包含第三方SDK或其他类似的应用程序，如您在我们平台上使用这类由第三方提供的服务时，您同意将由其直接收集和处理您的信息（如以嵌入代码、插件等形式）。目前微企乐APP中包含的第三方SDK有：
      <br />&nbsp;&nbsp;极光 SDK <br />&nbsp;&nbsp;使用目的：推送消息
      <br />&nbsp;&nbsp;信息类型：设备信息、网络信息、设备标识符、网络监测，设备的IMEI，Wi-Fi_mac地址，位置信息、软件安装列表
      <br />&nbsp;&nbsp;收集信息用途：极光推送服务为APP赋予消息和通知的推送能力。极光推送SDK会收集使用实现服务所必须的个人信息，通过加密的通道将消息和通知推送给您的终端用户。极光推送SDK收集使用的个人信息包括：1）设备信息：用于识别唯一用户，保证消息推送的精准送达；优化推送通道资源，我们会根据设备上不同APP的活跃情况，整合消息推送的通道资源，为开发者提高消息送达率；为开发者提供智能标签以及展示业务统计信息的服务；2）网络信息与位置信息：优化SDK与极光服务器的网络连接请求，保证服务的稳定性和连续性；实现区域推送的功能
      <br />&nbsp;&nbsp;官网链接：https://www.jiguang.cn/license/privacy
      <br />
      <br />&nbsp;&nbsp;腾讯x5内核 <br />&nbsp;&nbsp;使用目的：网页浏览
      <br />&nbsp;&nbsp;收集信息类型：系统UA、设备IMEI、设备IMSI、IP地址及安卓系统封装用户id
      <br />&nbsp;&nbsp;收集信息用途：使用x5内核、优化x5浏览内核
      <br />&nbsp;&nbsp;官网链接：https://x5.tencent.com/tbs/policy.html
      <br />
      <br />&nbsp;&nbsp;微信登录 <br />&nbsp;&nbsp;使用目的：微信登录
      <br />&nbsp;&nbsp;收集信息类型：常用设备信息（如IMEI/IMSI、SIM卡序列号/MAC地址）、网络信息
      <br />&nbsp;&nbsp;收集信息用途：应用内第三方账号登陆
      <br />&nbsp;&nbsp;官网链接：https://open.weixin.qq.com/
      <br />
      <br />&nbsp;&nbsp;支付宝登录 <br />&nbsp;&nbsp;使用目的：支付宝登录
      <br />&nbsp;&nbsp;收集信息类型：常用设备信息（如IMEI/IMSI、SIM卡序列号/MAC地址）、网络信息
      <br />&nbsp;&nbsp;收集信息用途：应用内第三方账号登陆
      <br />&nbsp;&nbsp;官网链接：https://opendocs.alipay.com/open/01g6qm
      <br />5、关于使用Cookie和同类技术 <br />1）
      在您使用我们的产品与/或服务时，我们可能会使用Cookie和同类技术收集您的一些个人信息，包括：您访问网站的习惯、您的浏览信息、您的登录信息，Cookie和同类技术收集该类信息是为了您使用我们的产品与/或服务的必要、简化您重复操作的步骤（如注册、登录）、便于您查看使用历史（如视频观看历史）、向您提供更切合您个人需要的服务内容和您可能更感兴趣的内容、保护您的信息和账号安全性、提升我们的产品和服务等。
      <br />2.）如果您拒绝我们使用Cookie及同类技术收集和使用您的相关信息，您可在浏览器具备该功能的前提下，通过您的浏览器的设置以管理、（部分/全部）拒绝Cookie与/或同类技术；或删除已经储存在您的计算机、移动设备或其他装置内的Cookie与/或同类技术，从而实现我们无法全部或部分追踪您的个人信息。您如需详细了解如何更改浏览器设置，请具体查看您使用的浏览器的相关设置页面。您理解并知悉：我们的某些产品/服务只能通过使用Cookie或同类技术才可得到实现，如您拒绝使用或删除的，您可能将无法正常使用我们的相关产品与/或服务或无法通过我们的产品与/或服务获得最佳的服务体验，同时也可能会对您的信息保护和账号安全性造成一定的影响。
      <br />6、我们以及我们的关联公司，可能将您的个人信息与我们的关联公司、合作伙伴及第三方服务供应商、承包商及代理（例如代表我们发出短信或推送通知的通讯服务提供商、为我们提供位置数据的地图服务供应商）分享（他们可能并非位于您所在的法域），为了我们向您提供或改善我们的服务；
      <br />•随着我们业务的持续发展，我们以及我们的关联公司有可能进行合并、收购、资产转让或类似的交易，您的个人信息有可能作为此类交易的一部分而被转移；
      <br />•依据法律要求必须向第三方提供您的用户信息的情形；
      <br />四、我们如何保护用户信息
      <br />&nbsp;&nbsp;我们使用各种安全技术和程序，以防信息的丢失、不当使用、未经授权阅览或披露。但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。
      <br />五、账户注销
      <br />&nbsp;&nbsp;您可以通过客服在线申请注销或通过其他我们公示的方式申请注销您的账号。当您注销账号后，您将无法再以该账号登录和使用我们的产品与服务；该账号下的内容、信息、数据、记录等将会被删除或匿名化处理（但法律法规另有规定或监管部门另有要求的除外，如依据《中华人民共和国网络安全法》规定，您的网络操作日志将至少保留六个月的时间）；微企乐账号注销完成后，将无法恢复。
      <br />&nbsp;&nbsp;如您在谨慎考虑后仍执意决定注销您的微企乐账号的，您可以在您使用的我们的产品与/或服务的相关功能设置页面或根据操作指引向我们提交注销申请，以微企乐H5为例，其中的账号注销路径为：个人中心—专属顾问—拨打专属顾问电话—告知专属顾问需注销账号。
      <br />&nbsp;&nbsp;我们会在收到您的注销申请，并在验证您的用户身份后的7个工作日内尽快解决。如果您在处置您的个人信息时有任何疑问，您可以通过本隐私政策第七条“联系我们”中公示的联系方式与我们沟通解决。
      <br />六、隐私政策的修改
      <br />&nbsp;&nbsp;由于法律法规的变更，以及为了与互联网的新发展和可能的发展趋势保持同步，我们可能会不定时修改本政策。因此，我们保留自行决定实施此类修改的权利，如该等修订造成您在本《隐私政策》下权利的实质减少，我们将在修订生效前通过在主页上显著位置提示或向您推送通知或以其他方式通知您。在该种情况下，若您继续使用我们的服务，即表示同意受经修订的本《隐私政策》的约束。
      <br />七、联系我们
      <br />1、微企乐及相关服务由北京微企利乐信息技术有限公司提供，注册地址：北京
      北京市海淀区信息路甲28号B座(二层)02C室098号，如果您对个人信息保护问题有投诉、建议、疑问，您可以将问题发送至（biz@weiqilile.com），我们核查并验证您的用户身份后会及时反馈您的投诉与举报。
      <br />2、如对本指引内容有任何疑问、意见或建议，您可通过biz@weiqilile.com与我们联系。
      <br /><br />
      <div style="text-align: right">北京微企利乐信息技术有限公司</div>
    </div>
    <div class="text" v-if="title == '用户协议'">
      <div class="title">用户服务协议</div>
      欢迎阅读《用户服务协议》(以下简称“本协议”)。本协议阐述之条款和条件适用于您（以下简称“用户”）使用的各种产品和服务。
      <br />1. 服务协议的确认
      <br />1.微企乐同意按照本协议的规定及其不时发布的操作规则提供基于互联网移动网的微企乐小程序、微企乐官方网站、微企乐等相关服务（以下简称“微企乐”）。
      <br />1.2
      微企乐使用人（以下简称“用户”）应当基于了解本协议全部内容、在独立思考的基础上认可、同意本协议的全部条款并按照页面上的提示完成全部的注册程序，用户的注册、登录、使用等行为将视为完全接受本协议及微企乐公示的各项规则、规范。
      <br />1.3
      微企乐享有对微企乐、微企乐官方网站上一切活动的监督、提示、检查、纠正等权利。
      <br />2. 服务内容 <br />2.1
      微企乐的具体内容由微企乐根据实际情况提供，包括但不限于授权用户通过其帐号上传、搜索并向互相关联的用户推送相关信息、授权用户对其进行收录、分享等，微企乐有权对其提供的服务或产品形态进行升级或其他调整，均以网站内容更新的方式通知用户，不单独通知用户。
      <br />2.2
      微企乐提供的微企乐中可能包括广告，用户同意在使用过程中显示微企乐和第三方供应商、合作伙伴提供的广告。
      <br />2.3
      微企乐仅提供与微企乐相关的技术服务等，除此之外与相关网络服务有关的设备（如个人电脑、手机、及其他与接入互联网或移动网有关的装置）及所需费用（如为接入互联网而支付的电话费及上网费、为使用移动网而支付的手机费）均应由用户自行负担。
      <br />3. 服务变更、中断或终止 <br />3.1
      鉴于网络服务的特殊性（包括但不限于不可抗力、网络稳定性、系统稳定性、技术故障、用户所在位置、用户关机、用户手机病毒或恶意的网络攻击行为及其他任何技术、互联网络、通信线路、内容侵权等原因），用户同意，微企乐有权随时中断或终止部分或全部微企乐，微企乐将尽可能及时以合理方式通知用户，并尽可能在第一时间对此进行修复。但对因此导致用户不能发送和接受阅读信息、或接发错信息，微企乐不承担任何责任，用户须承担以上风险。
      <br />3.2
      用户理解，微企乐需要定期或不定期地对提供微企乐的平台或相关设备进行检修和维护，如因此类情况而造成服务在合理期间内的中断，微企乐将尽可能事先通知，但无需为此承担任何责任。
      <br />3.3
      用户提供的个人资料不真实、用户违反法律法规国家政策或本协议规定的使用规则，微企乐有权随时中断或终止向用户提供本协议项下的微企乐，而无需对用户或任何第三方承担任何责任。
      <br />4. 使用规则 4.1
      用户在申请注册微企乐时，必须向微企乐提供准确的主体资料（用户为个人时，包括姓名、性别、出生年月、电话、邮箱等；用户为单位时，包括名称、注册地址、注册号等），如主体资料有任何变动，必须及时更新。因用户提供资料的真实性问题导致协议双方或第三方的任何损失均由用户承担。
      <br />4.2
      用户注册成功后，将获得一个微企乐帐号及由用户设置的密码，该用户帐号和密码由用户负责保管；用户应当对该用户帐号所有行为负相关法律责任。
      <br />用户在使用微企乐过程中，必须遵循以下原则: <br />
      (1)遵守中国有关的法律和法规；
      <br />(2) 不得为任何非法目的而使用微企乐； <br />(3)
      遵守所有与网络服务有关的网络协议、规定和程序； <br />(4)
      不得利用微企乐系统进行任何可能对互联网的正常运转造成不利影响的行为；
      <br />(5) 不得利用微企乐网络服务系统进行任何不利于微企乐的行为。 <br />4.3
      用户不得使用微企乐制作、上传、发送、传播敏感信息和违反国家法律制度的信息，包括但不限于下列信息:
      <br />(1) 反对宪法所确定的基本原则的； <br />(2)
      危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的； <br />(3)
      损害国家荣誉和利益的； <br />(4) 煽动民族仇恨、民族歧视，破坏民族团结的；
      <br />(5) 破坏国家宗教政策，宣扬邪教和封建迷信的； <br />(6)
      散布谣言，扰乱社会秩序，破坏社会稳定的； <br />(7)
      散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的； <br />(8)
      侮辱或者诽谤他人，侵害他人合法权益的； <br />(9)
      含有法律、行政法规禁止的其他内容的。 <br />4.4
      <br />用户同意在任何情况下不使用其他用户的帐号或密码。在您怀疑他人在使用您的帐号或密码时，您同意立即通知微企乐。
      <br />
      4.5
      用户有权更改、删除在微企乐中的个人资料、注册信息及传送内容等，但删除有关信息的同时也会删除任何您储存在系统中的文字和图片。用户需承担该风险。
      <br />4.6
      如因用户违反本协议中的任何条款，微企乐有权依据本协议终止对违约用户微企乐帐号提供服务。
      <br />4.7
      如用户注册微企乐帐号后长期不登录该帐号，微企乐有权回收该帐号，以免造成资源浪费，由此带来问题均由用户自行承担。
      <br />5. 知识产权 <br />5.1
      微企乐提供微企乐中所包含的任何文本、图片、标识、音频、视频资料均受著作权、商标权、专利权及其他财产权法律的保护。
      <br />5.2
      未经相关权利人和微企乐的同意，上述资料均不得以任何方式被展示于其他任何第三方平台或被用于其他任何商业目的；用户不得擅自复制、修改、编纂上述内容、或创造与内容有关的衍生产品。
      <br />6. 隐私保护 <br />6.1
      本协议所指的“隐私”包括《电信和互联网用户个人信息保护规定》第4条规定的用户个人信息的内容以及未来不时制定或修订的法律法规中明确规定的隐私应包括的内容。
      <br />6.2
      保护用户隐私和个人数据是微企乐的一项基本制度，微企乐将采取各种制度、安全技术和程序等措施来保护用户隐私和个人数据不被未经授权的访问、使用或泄漏，并保证不会将单个用户的注册资料及用户在使用微企乐时存储在微企乐的非公开内容向除合作单位以外的第三方公开或用于任何非法的用途，但下列情况除外：
      <br />(1) 事先获得用户的明确授权； <br />(2) 根据有关法律法规的要求；
      <br />(3) 按照相关政府主管部门的要求； <br />(4) 为维护社会公众的利益；
      <br />(5)
      用户侵害本协议项下微企乐的合法权益的情况下而为维护微企乐的合法权益所必须。
      <br />6.3
      用户在注册微企乐帐号或使用微企乐的过程中，需要提供一些必要的信息，例如：姓名、电话、邮箱、性别、出生年月、名称、注册地址、注册号等。为向用户提供帐号注册服务或进行用户身份识别，需要用户填写手机号码；部分功能需要用户授权使用用户的相机或相册；部分功能需要用户授权访问用户的手机通讯录等。若用户不授权或提供的信息不完整，则无法使用本服务或在使用过程中受到限制。用户授权提供的信息，微企乐承诺将采取措施保护用户的个人信息安全。
      <br />6.4
      为了向用户提供更好的用户体验和提高微企乐的服务质量，微企乐将可能会收集使用或向第三方提供用户的非个人隐私信息。微企乐将对该第三方使用用户个人数据的行为进行监督和管理，尽一切可能努力保护用户个人信息的安全。
      <br />7. 免责声明 <br />7.1
      用户在使用微企乐的过程中应遵守国家法律法规及政策规定，对其所制作、上传、发送、传播的信息和内容承担任何法律责任，与微企乐无关。
      <br />7.2
      微企乐在其页面上向用户显示、推送的任何信息和内容如系微企乐利用技术手段根据用户指令从互联网任何第三方网站搜索、定位、匹配后推荐给用户而非微企乐单方制作的，则该显示、推送的信息和内容并不代表微企乐及微企乐的观点，微企乐并不对上述信息的准确性和正确性负责。
      <br />7.3微企乐提供微企乐中所包含的任何文本、图片、标识、音频、视频资料均为教学用途，不涉及任何实体物品的商业活动。
      <br />7.4微企乐在其页面上向用户显示、推送的任何信息和内容如存在侵权任何第三方知识产权的嫌疑，权利人和相关利害关系人应当向微企乐发出权利通知，微企乐经过核实后根据有关法律法规有权采取包括但不限于断开该侵权内容的链接或删除并停止传输该侵权内容，但微企乐并不对该侵权内容承担法律责任。
      <br />7.5用户违反本协议的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户同意赔偿微企乐，使之免受损害。
      <br />8. 其他条款 <br />8.1
      微企乐有权随时修改本协议的任何条款，一旦本协议的内容发生变动，微企乐将会在微企乐平台中公布修改后的协议内容或通过其他适当方式向用户公布修改内容。用户不同意上述修改内容，有权选择停止使用微企乐，但用户继续使用，则视为接受微企乐对本协议相关条款的修改。
      <br />8.2
      本《协议》所定的任何条款无论因何种原因部分或全部无效或不具有执行力，本协议的其余条款仍应有效并具备约束力。
      <br />8.3
      本协议的订立、执行和解释及纠纷的解决均应适用中华人民共和国法律并受中华人民共和国法院管辖。如双方就本协议内容或执行发生任何纠纷或争议，首先应友好协商解决，协商不成的，任何一方均可向微企乐所在地的有管辖权的人民法院提起诉讼。
      <br />8.4
      本协议的版权归微企乐所有，本协议各项条款内容的最终解释权及修改权归微企乐所有。
    </div>
    <div class="text" v-if="title == '授权协议'">
      <div class="title">用户授权协议</div>
      <br />《用户授权协议》（以下简称“本协议”）是微企乐与用户（以下简称“您”）所订立的有效合约。在接受本协议前，您应仔细阅读本协议项下全部内容，并对加粗字体显示部分重点阅读。本协议的效力为在本协议项下为您提供的一切服务。
      <br />1、您确认，您同意接受本协议时，您具有完全民事权利能力和民事行为能力，能够独立承担民事责任；本协议内容不受您所属国家或地区法律排斥。如您不具备前述条件或无法同意本协议的任意内容，请勿进行后续操作，并立即停止授权。
      <br />2、您确认，您接受本协议并确认授权后，即视为您同意并授权将您的微企乐账户的相关信息及数据传递。授权页面会展示授权对象、授权信息等，授权信息通过加密通道传递。微企乐会要求该第三方依法使用您所授权的信息，并应对您的信息保密。
      <br />3、您理解，第三方提供的服务由该第三方独立运营，并承担全部责任。如因该第三方的站点或软件存在漏洞、故障、病毒等原因造成您的相关权益受损；或其使用您的信息产生的纠纷；或第三方提供的服务违反相关法律法规或本协议约定，您可以请微企乐协调，但您不应就以上问题和登录、使用第三方的站点或软件造成的后果要求微企乐承担任何责任。
      <br />4、您同意，微企乐有权根据业务发展需要对本协议的内容予以变更，并在微企乐官方渠道公告，无需另行单独通知您。变更后的协议内容一经公布，即代替原来的协议内容，您须定期审阅本协议及变更后的内容。
      <br />5、本协议未尽事宜，参照微企乐相关协议执行；相关协议与本协议有冲突的，以本协议为准。
      <br />6、因本协议产生任何争议，双方应友好协商解决；协商不成，任何一方有权向被告住所地有管辖权的人民法院提起诉。
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "",
    };
  },
  created() {
    this.title = this.$route.query.title;
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.box {
  line-height: 30px;
  padding: 15px;
  width: 345px;
  margin: 0 auto;
  font-size: 12px;
  .title {
    font-size: 15px;
    font-weight: bold;
  }
}
</style>
